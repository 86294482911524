// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyCgQCZ0AOBN1uCrUNVPEWj3DKme2fXbgJc",
    authDomain: "ingamefun-ds.firebaseapp.com",
    databaseURL: "https://ingamefun-ds.firebaseio.com",
    projectId: "ingamefun-ds",
    storageBucket: "ingamefun-ds.appspot.com",
    messagingSenderId: "66665224225",
    appId: "1:66665224225:web:b00443c5e27bac5346eb60"
  },
  link : "http://localhost:3000"
};

