import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { auth, User } from 'firebase';
import { Observable, Subject, from } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ProfileModel } from '../pages/profile/profile.model';
import { ServerService } from './server.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authUser: string | null;
  currentUser: User;
  user: Observable<firebase.User>;
  userProviderAdditionalInfo: any;
  redirectResult: Subject<any> = new Subject<any>();

  constructor(
    public angularFireAuth: AngularFireAuth,
    public platform: Platform,
    private serverService : ServerService,
    private nativeStorage: NativeStorage,
    private storage: Storage
  ) { 
    this.user = angularFireAuth.authState;
  }

   getUid() {

        const uid =  this.angularFireAuth.currentUser.then(
          res => {
           this.authUser = res.uid
          }
        )

        return this.authUser;
  }


  getRedirectResult(): Observable<any> {
    return this.redirectResult.asObservable();
  }

  setProviderAdditionalInfo(additionalInfo: any) {
    this.userProviderAdditionalInfo = {...additionalInfo};
  }

  public getProfileDataSource() {
    return this.angularFireAuth.user
    .pipe(
      filter((user: User) => user != null),
      map((user: User) => {
        return this.getProfileData();
      }),
      take(1) // this.angularFireAuth.user never completes so we use take(1) in order to complete after the first value is emitted
    );
  }

  /**
   * getToken
   */
  public async getToken() {
    return await this.angularFireAuth.currentUser
    .then(user => { 
      const token = user.getIdToken(true)
      this.storage.set('idToken', token)
      .then(
        () => console.log('Stored item!'),
        error => console.error('Error storing item', error)
      );
      return token;
    
    });

  }

  public async startSetupStipe(user) {

  
      try {
          await this.getToken().then((res)=>{
            const status = this.serverService.checkToken(res);
            if (status) this.serverService.startStripe(res);
/*           this.storage.get('idToken')
              .then(
                data => {console.log(data); 
                  const status = this.serverService.checkToken(data);
                  //if (status) this.serverService.startStripe(data);
                 },
                error => console.error(error)
              ); */
        })

      }
      catch(e) {
        console.log('Error!', e);
      }
    
   
    //
  }

  public finishSetupStripe() {


  }

  public getProfileData() {
    const userModel = new ProfileModel();
    let providerData : any = this.currentUser.providerData[0];

    if (this.userProviderAdditionalInfo) {
      providerData = {...providerData, ...this.userProviderAdditionalInfo};
    }

    // Default imgs are too small and our app needs a bigger image
    switch (providerData.providerId) {
      case 'facebook.com':
        userModel.image = providerData.photoURL + '?height=400';
        break;
      case 'password':
        userModel.image = 'https://s3-us-west-2.amazonaws.com/ionicthemes/otros/avatar-placeholder.png';
        break;
      case 'twitter.com':
        userModel.image = providerData.photoURL.replace('_normal', '_400x400');
        break;
      case 'google.com':
        userModel.image = providerData.photoURL.split('=')[0];
        break;
      default:
        userModel.image = providerData.photoURL;
    }
    userModel.name = providerData.name || providerData.displayName || 'What\'s your name?';
    userModel.role = 'How would you describe yourself?';
    userModel.description = providerData.description || 'Anything else you would like to share with the world?';
    userModel.phoneNumber = providerData.phoneNumber || 'Is there a number where I can reach you?';
    userModel.email = providerData.email || 'Where can I send you emails?';
    userModel.provider = (providerData.providerId !== 'password') ? providerData.providerId : 'Credentials';

    return userModel;
  }

  signOut(): Observable<any> {
    return from(this.angularFireAuth.signOut());
  }

  signInWithEmail(email: string, password: string): Promise<auth.UserCredential> {
    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }

  signInAnonymously () {
    return this.angularFireAuth.signInAnonymously()
  }

  signUpWithEmail(email: string, password: string): Promise<auth.UserCredential> {
    return this.angularFireAuth.createUserWithEmailAndPassword(email, password);
  }

  socialSignIn(providerName: string, scopes?: Array<string>): Promise<any> {
    const provider = new auth.OAuthProvider(providerName);

    // add any permission scope you need
    if (scopes) {
      scopes.forEach(scope => {
        provider.addScope(scope);
      });
    }

    if (this.platform.is('desktop')) {
      return this.angularFireAuth.signInWithPopup(provider);
    } else {
      // web but not desktop, for example mobile PWA
      return this.angularFireAuth.signInWithRedirect(provider);
    }
  }

  signInWithFacebook() {
    const provider = new auth.FacebookAuthProvider();
    // const scopes = ['user_birthday'];
    return this.socialSignIn(provider.providerId);
  }

  signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    const scopes = ['profile', 'email'];
    return this.socialSignIn(provider.providerId, scopes);
  }

  signInWithTwitter() {
    const provider = new auth.TwitterAuthProvider();
    return this.socialSignIn(provider.providerId);
  }
}
