import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Platform, MenuController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoaderService } from './services/loader.service';
import { NewApptComponent } from '../app/components/new-appt/new-appt.component';
import { NewChargeComponent } from '../app/components/new-charge/new-charge.component';
import { NewVideoComponent } from '../app/components/new-video/new-video.component';
import { ChargePage } from '../app/charge/charge.page';
import { NewReminderComponent } from '../app/components/new-reminder/new-reminder.component';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';
import { ServerService } from './services/server.service';
import { OnInit } from '@angular/core';




@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit{
user$;
thisUser : any = true;
admin = true;
modifyform: FormGroup;
public docid: string;

demo = false;

productlist = {
  producttitle: "",
  productprice: "",
  productdesc: ""

};
   constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public fbauth: AngularFireAuth,
    private fbstore: AngularFirestore,
    public ngroute: Router,
    private loaderservice: LoaderService,
    public menuCtrl: MenuController,
    public modalCtrl: ModalController,
    private storageService: StorageService,
    private authService : AuthService,
    private serverservice : ServerService, 
  ) {

    
    console.log(this.ngroute.url);

    this.platform.ready().then(async () => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      await this.authService.user.subscribe(data =>{ console.log("data: ", data); this.thisUser = data;  console.log('this.thisUserAPP', this.thisUser); });
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    


    //console.log("this.authService.user$: ", this.authService.user);
  }

  

    async logout(): Promise<void> {
    await this.fbauth.signOut().then(() => {
      //location.reload(true)
      this.ngroute.navigate(['login']);
    });
  }

  async ngOnInit() {

    console.log('this.thisUserAPP', this.thisUser);
   
  }

  openPayouts() {
    this.authService.getToken().then(res => {
      this.serverservice.getExpressLink(res, false)
    })
     
  }

  openAccount() {
    this.authService.getToken().then(res => {
      this.serverservice.getExpressLink(res, true)
    })
  }



  removeDemoTag(){

    document.getElementById('demoTag').style.display = 'none';

  }


  // needs programmatic way to get the account number


  async openModal(type: string) {
    let typeToAdd;
    switch(type){
       case 'appt':
         typeToAdd = NewApptComponent
         break;
      case 'charge':
        typeToAdd = ChargePage
        break;           
      case 'video':
        typeToAdd = NewVideoComponent
        break;   
      case 'reminder':
        typeToAdd = NewReminderComponent
        break;  
    }

    const modal = await this.modalCtrl.create({
      component: typeToAdd,
      componentProps: {
        "paramID": 123,
        "paramTitle": "Test Title"
      }
    });

    return await modal.present();
  }

  
  
  toggleMenu() {
    this.menuCtrl.toggle(); //Add this method to your button click function
  }

getUser(uid) {
  return this.fbstore.collection('users').doc(uid).get().subscribe(data => {


    //console.log("data.data()): ", data.data());
    this.admin = data.data()["admin"]; 
    
  });
  
}
  
}

