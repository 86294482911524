import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  request;  
  appointments;
  appointmentID;
  requestID;

  getRequestID(Id) {
    return this.requestID;
  }

  setRequestID(Id) {
    this.requestID = Id;
  }

  setAppointments(appointments) {
    this.requestID = appointments;
    console.log('this.appointments: ', this.appointments)
  }
}

