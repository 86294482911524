import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { DbService } from 'src/app/services/db.service';
import { ServerService } from 'src/app/services/server.service';
import { ToastService } from 'src/app/services/toast.service';



@Component({
  selector: 'app-new-appt',
  templateUrl: './new-appt.component.html',
  styleUrls: ['./new-appt.component.scss'],
})
export class NewApptComponent implements OnInit {
  @Input() thisUser: string | null;
  @Input() doc : object | null;
  @Input() source: string | null;
  
  public requestlist = [];
  public appointmentlist = [];
  public isLoading: any = true;
  stripeACC:string;


  

  
  modifyform: FormGroup;
  public docid: string;

  patient;
  edit:boolean = false;

  constructor(
    public modifyformbuilder: FormBuilder,
    private serverservice: ServerService,
    private toastservice: ToastService,
    private fbstore: AngularFirestore,
    public alertcontrol: AlertController,
    //private loaderservice : LoaderService,
    private navCtrl: NavController,
    //private popoverController:PopoverController
    private modalCtrl :ModalController,
    private Dbservice: DbService
  ) { 

  }
  

  ngOnInit() {


    console.log('this.doc: ', this.doc);
    this.fbstore.collection(`users`).doc(`${this.thisUser}`).get()
    .subscribe(res => {
      this.stripeACC = res.get('stripeACC');
    })

    this.edit = this.source == 'edit' ? true : false;

    //console.log('this.thisUser44: ', this.thisUser)
    //console.log('this.thisDoc44: ', this.doc)
    this.modifyform = this.modifyformbuilder.group({
      name: [ this.doc['name'] || '', [Validators.required]],
      phone: [this.doc['phone']  || '', [Validators.required, Validators.minLength(2), Validators.pattern("^[0-9]*$")] ],
      //zipcode: ['', [Validators.required, Validators.minLength(5), Validators.pattern("^[0-9]*$")] ],
      dob: [this.doc['dob']  || '', [Validators.required ] ],
      email: [this.doc['email']  || '', [Validators.required, Validators.email, Validators.minLength(6)] ],
      reason: [this.doc['reason'] || '', [Validators.required, Validators.minLength(6)] ],
      apptStart: [ this.doc['apptStart'] || '', [Validators.required ] ],
      apptEnd: [ this.doc['apptEnd'] || '', [Validators.required ] ],
      price: [this.doc['price']  || '', [Validators.required, Validators.minLength(2), Validators.pattern("^[0-9]*$")] ],

    })
  }

  closeModal(){
    this.modalCtrl.dismiss();
  }

  apptEnd() {
    console.log("appt end time : ", new Date(this.modifyform.get('apptStart').value).getTime(), (this.modifyform.get('apptEnd').value * 60000) )
    return new Date(new Date(this.modifyform.get('apptStart').value).getTime() + (this.modifyform.get('apptEnd').value * 60000)).toLocaleString();
  }

  async doModify() {

    if (!this.patient) {
      this.patient = "null"
    }
    
    let scheduleObj = {
      name: this.modifyform.get('name').value,
      phone: this.modifyform.get('phone').value,
      email: this.modifyform.get('email').value,
      apptEnd: this.apptEnd(),
      //apptEnd: this.modifyform.get('apptEnd').value,
      dob: this.modifyform.get('dob').value,
      reason: this.modifyform.get('reason').value,
      apptStart: this.modifyform.get('apptStart').value,
      price: this.modifyform.get('price').value,
      paymentStatus: false,
      patient: this.patient,
      doctor: this.thisUser,
      stripeACC : this.stripeACC
    }

    console.log("productobj: ", scheduleObj)
    console.log("productobj: ", this.thisUser)
    try{
    
      await this.fbstore.collection(`/users/${this.thisUser}/appointments`).doc(`${this.docid}`).set(scheduleObj).then(async data => {

        console.log('this.source: ', this.source)
      if (this.source == "request") this.fbstore.collection(`/users/${this.thisUser}/requests`).doc(this.doc['docid']).delete()
        console.log('show me: ', data);
       // this.serverservice.emailReminder(data.id);
       // this.ngroute.navigate(['requests']);
       this.modalCtrl.dismiss();
      })
    }catch(error){
      this.toastservice.showToast(error.message, 2000);
      console.log(error.message);
    }
  }


}
