import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastService } from '../services/toast.service';
import { ServerService } from '../services/server.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from "@angular/fire/firestore";
import { ModalController, NavController } from '@ionic/angular';

declare var Stripe;
@Component({
  selector: 'app-charge',
  templateUrl: './charge.page.html',
  styleUrls: ['./charge.page.scss'],
})
export class ChargePage {

  modifyform: FormGroup;
  stripe = Stripe('pk_test_hbHkTUgq5rpJqCXZRI6Wfv0j');
  card: any;

  id ;


  constructor(public modifyformbuilder: FormBuilder,
    private serverservice: ServerService,

    public ngroute: Router,
    public acroute: ActivatedRoute,
    private fbstore: AngularFirestore,
    private navCtrl: NavController,
    public modalCtrl : ModalController) {
    var checkoutButton = document.getElementById('checkout-button');
    

    this.modifyform = this.modifyformbuilder.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(2), Validators.pattern("^[0-9]*$")] ],
      zipcode: ['', [Validators.required, Validators.minLength(5), Validators.pattern("^[0-9]*$")] ],
      email: ['', [Validators.required, Validators.email, Validators.minLength(6)] ],
      price: ['', [Validators.required, Validators.minLength(2), Validators.pattern("^[0-9]*$")] ]
    })
  
  }


  ngOnInit() {

    this.setupStripe();
  }

  closeModal(){
    this.modalCtrl.dismiss();
  }



  async setupStripe() {
    let elements = this.stripe.elements();
    var style = {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.card = elements.create('card', { style: style });
    console.log(this.card);
    this.card.mount('#card-element');

    this.card.addEventListener('change', event => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    var form = document.getElementById('payment-form');
    form.addEventListener('submit', event => {
      event.preventDefault();

      this.stripe.card

      this.stripe.createSource(this.card).then(result => {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          console.log(result);
          this.makePayment(result)
        }
      });
    });
  }

  submitForm() {

    console.log('made it to button')
    this.stripe.card

    this.stripe.createSource(this.card).then(result => {
      if (result.error) {
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        console.log(result);
        this.makePayment(result)
      }
    });

}

  
 makePayment(token) {
  
  let chargeobj = {
    name: this.modifyform.get('name').value,
    phone: this.modifyform.get('phone').value,
    email: this.modifyform.get('email').value,
    amount: this.modifyform.get('price').value,
    // note: this.modifyform.get('note').value || '',
    // description: this.modifyform.get('description').value || ''
    
  }

  console.log('chargeobj', chargeobj)
     this.serverservice.newcharge(token.source.id, 
                                  chargeobj.phone, 
                                  chargeobj.name, 
                                  chargeobj.email, 
                                  'chargeobj.description', 
                                  'chargeobj.note', 
                                  chargeobj.amount);
  }


}