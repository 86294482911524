import { Injectable } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { ModalController, NavController } from '@ionic/angular';
//import { response } from 'express';



@Injectable({
  providedIn: 'root'
})
export class ServerService {

 paymentDetails = {
     id :  '',
     price: ''

 }

 dev = true;
  // base = `http://localhost:3000`;
  base = ``;

  constructor(
    private toastservice: ToastService, 
    private navCtrl : NavController, 
    private modal: ModalController,
    ) { 
  }


  //check if token is real
  async checkToken(token: string) {

    const options = {
      method: 'POST',
      body: JSON.stringify({token}),
      headers: new Headers({
          'Content-Type': 'application/json'
      })
  }

    fetch(`${this.base}/token`, options)
    .then(res => {return true})
    //.then(posts =>  {/* this.toastservice.showToast("Successfuly Canceled", 2000) */console.log(posts);})
    .catch(error => /* this.toastservice.showToast(error.message, 2000) */{console.log(error); return false});
  }

    //start stripe 
    async startStripe(token: string) {

      const options = {
        method: 'POST',
        body: JSON.stringify({token}),
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    }
  
      fetch(`${this.base}/doctors/stripe/authorize`, options)
      .then(res =>  res.json())
      .then(posts =>  {/* this.toastservice.showToast("Successfuly Canceled", 2000) */console.log(posts); window.location.replace(posts)})
      .catch(error => /* this.toastservice.showToast(error.message, 2000) */console.log(error));
    }

        //check if token is real
        async finishStripe(code: string, token: string) {

          const options = {
            method: 'POST',
            body: JSON.stringify({code, token}),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        }
      
          fetch(`${this.base}/doctors/stripe/token`, options)
          .then(res => res.json())
          .then(posts2 =>  {/* this.toastservice.showToast("Successfuly Canceled", 2000) */console.log(posts2);})
          .catch(error => /* this.toastservice.showToast(error.message, 2000) */console.log(error) ); // maybe redirect back to the stripe setup page or something 
        }

        
async getExpressLink(token, account) {

  const options = {
      method: 'POST',
      headers: new Headers({
          'Content-Type': 'application/json'
      }),
      body: JSON.stringify({token, account})
  }

  return await fetch(`${this.base}/doctors/stripe/dashboard`, options)
  .then(response => {return response.json()})
  .then(posts =>  {/* this.toastservice.showToast("Successfuly Canceled", 2000) */console.log(posts);  window.location.replace(posts.link) })
  .catch(error => this.toastservice.showToast(error.message, 2000)/* console.log(error)*/ );
}




  //email notifications only
  async cancelAppointment(id: string) {

    const options = {
      method: 'POST',
      body: JSON.stringify({id: id, type: 1}),
      headers: new Headers({
          'Content-Type': 'application/json'
      })
  }

    fetch(`${this.base}/doctors/cancelAppointment`, options)
    .then(res => res.json())
    .then(posts =>  this.toastservice.showToast("Successfuly Canceled", 2000)/* console.log(posts) */)
    .catch(error => this.toastservice.showToast(error.message, 2000)/* console.log(error) */);
  }

  async emailReceipt(token: string, id: string) {

    const options = {
      method: 'POST',
      body: JSON.stringify({token, id }),
      headers: new Headers({
          'Content-Type': 'application/json'
      })
  }

  fetch(`${this.base}/doctors/sendReceipt`, options)
  .then(res => res.json())
  .then(posts =>  this.toastservice.showToast("Reminder Sent", 2000)/* console.log(posts) */)
  .catch(error => this.toastservice.showToast(error.message, 2000)/* console.log(error) */);
  }

  async emailReminder(id: string) {

    const options = {
      method: 'POST',
      body: JSON.stringify({id, type: 1}),
      headers: new Headers({
          'Content-Type': 'application/json'
      })
  }

  fetch(`${this.base}/doctors/sendReminder`, options)
  .then(res => res.json())
  .then(posts =>  this.toastservice.showToast("Reminder Sent", 2000)/* console.log(posts) */)
  .catch(error => this.toastservice.showToast(error.message, 2000)/* console.log(error) */);
  }

  async smsReminder(id: string) {

    const options = {
      method: 'POST',
      body: JSON.stringify({id: id, type: 2}),
      headers: new Headers({
          'Content-Type': 'application/json'
      })
  }

  fetch(`${this.base}/doctors/sendReminder`, options)
  .then(res => res.json())
  .then(posts =>  this.toastservice.showToast("Reminder Sent", 2000)/* console.log(posts) */)
  .catch(error => this.toastservice.showToast(error.message, 2000)/* console.log(error) */);
  }

  async charge(id, thisUser, token, email) {

    const options = {
      method: 'POST',
      body: JSON.stringify({id, thisUser, token, email}),
      headers: new Headers({
          'Content-Type': 'application/json'
      })
  }

  await fetch(`${this.base}/doctors/stripe/charge`, options)
  .then(res => res.json())
  .then(success =>  {this.toastservice.showToast("Reminder Sent", 2000); this.modal.dismiss();  this.navCtrl.navigateRoot(`connect?id=${id}`);  })
  .catch(error => {this.toastservice.showToast(error.message, 4000); console.log(error.message)});
  }

  async newcharge( token, phone, name, email, description, note, amount ) {

    const options = {
      method: 'POST',
      body: JSON.stringify({ token, phone, name, email, description, note, amount}),
      headers: new Headers({
          'Content-Type': 'application/json'
      })
  }

  await fetch(`${this.base}/newcharge`, options)
  .then(res => res.json())
  .then(success =>  {this.toastservice.showToast("Payment Successfull", 2000)}/* console.log(posts) */)
  .catch(error => {this.toastservice.showToast(error.message, 2000); console.log(error)});
  }



async connect(id, person) {
    const options = {
        method: 'POST',
        body: JSON.stringify({id, person}),
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    }
  
    return await fetch(`${this.base}/common/connect`, options)
    .then(function(response) {
      console.log('response55444: ', response)
        return response.json();
    })
    .catch(error => /* this.toastservice.showToast(error.message, 2000) */console.log(error) );
}


async newRequest(doctor, body) {
  const options = {
      method: 'POST',
      headers: new Headers({
          'Content-Type': 'application/json'
      }),
      body: JSON.stringify({doctor, ...body})
  }

  return await fetch(`${this.base}/patients/requestcallback`, options)
  .then(response => {return response.json()})
  .catch(error => this.toastservice.showToast(error.message, 2000)/* console.log(error)*/ );
}


async getTransactions(token) {
    const options = {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({ACC : token})
    }
  
    return await fetch(`${this.base}/doctors/stripe/transactions`, options)
    .then(response => {return response.json()})
    .catch(error => this.toastservice.showToast(error.message, 2000)/* console.log(error)*/ );
}

async getTransactions2(token) {
  const options = {
      method: 'POST',
      headers: new Headers({
          'Content-Type': 'application/json'
      }),
      body: JSON.stringify({acc : 'acct_1Hpvt9QlbCL0ZRd9'})
  }

  return await fetch(`${this.base}/stripe/acc-tr`, options)
  .then(response => {return response.json()})
  .catch(error => this.toastservice.showToast(error.message, 2000)/* console.log(error)*/ );
}


}
