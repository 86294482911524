import { Injectable } from '@angular/core';
import { AngularFirestore,AngularFirestoreCollection } from '@angular/fire/firestore';
import { ToastService } from './toast.service';
import RequestItem from '../models/requestItem'
import { Storage } from '@ionic/storage';
import { map } from 'rxjs/operators';
import { identifierModuleUrl } from '@angular/compiler';


@Injectable({
  providedIn: 'root'
})
export class DbService {
  appointmentList;
  requestList;
  tutorials;
  private dbPath = '/users';
  apptRef: AngularFirestoreCollection<RequestItem> = null;
  reqRef: AngularFirestoreCollection<RequestItem> = null;
  itemRef: AngularFirestoreCollection<RequestItem> = null;
  items;
  constructor(
    private fbstore: AngularFirestore,
    private toastservice: ToastService,
    private storage: Storage
    ) { 
      //this.apptRef = fbstore.collection(this.dbPath);
    }

  queryItems (path) {

    this.itemRef = this.fbstore.collection<RequestItem>(path, ref => {
      // Compose a query using multiple .where() methods
      return ref
              //.where('type', '==', 1)
                
    });
    
     return this.itemRef.valueChanges({idField : 'docid'});

    
  
  }

  getInfo (path) {

     return this.fbstore.doc(path).get()
  
  }

    getRequestTotal(uid){
      this.fbstore.collection(`users/${uid}/requests`).doc('total').get().subscribe(
      res =>  {

        //console.log('res for total', res.get('count')); 
        this.storage.set('reqTotal', res.get('count') )
      }
      )
    }


    getAll(path, name) {
     this.fbstore.collection(path).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>{
          //c.payload.doc.data
          //console.log('doc data: ',  c.payload.doc.data())
          let pay = c.payload.doc.data();
          pay['id']= c.payload.doc.id;
          return (pay)}
        )
      )
    ).subscribe(data => {
      //this.tutorials = data;
      this.storage.set(name, data)
      console.log('should be pay ', this.tutorials);
      
    });
    return this.tutorials
    }

    create(uid, requsestItem: any): any {
      return this.fbstore.collection(`/users/${uid}/appointments`).add({ ...requsestItem })
    }
  
    update(id: string, data: any): Promise<void> {
      return this.apptRef.doc(id).update(data);
    }
  
    delete(id: string): Promise<void> {
      return this.apptRef.doc(id).delete();
    }

   getAppointments(thisUser){
    try{

       this.fbstore.collection(`users`).doc(thisUser).collection("appointments").snapshotChanges()
      .subscribe(data => {
        //console.log(data);
        this.appointmentList = data.map(result => {
          console.log(result.payload.doc.data())
          
          return {
            docid: result.payload.doc.id,
            name: result.payload.doc.data()["name"],
            price: result.payload.doc.data()["price"],
            email: result.payload.doc.data()["email"],
            phone: result.payload.doc.data()["phone"],
            dob: result.payload.doc.data()["dob"],
            reason: result.payload.doc.data()["reason"],
            appointment: result.payload.doc.data()['appointment']
          }

        })

      });
/*       await this.fbstore.collection('users').doc(this.thisUser).collection("appointments").get().subscribe(res =>{
        res.forEach(item =>{
          this.list.push(item.data())
        })
      })
      console.log('this.list: ', this.list) */
      console.log('this.list: ', this.appointmentList)
      
    }catch(error){
      this.toastservice.showToast(error.message, 2000);
      //console.log(error.message);
    }
  }

  async getRequests(thisUser){
    try{
      await this.fbstore.collection(`users`).doc(thisUser).collection("requests").snapshotChanges()
      .subscribe(data => {
        //console.log(data);
        this.requestList = data.map(result => {
          //console.log(result)
          
          return {
            docid: result.payload.doc.id,
            name: result.payload.doc.data()["name"],
            email: result.payload.doc.data()["email"],
            phone: result.payload.doc.data()["phone"],
            dob: result.payload.doc.data()["dob"],
            reason: result.payload.doc.data()["reason"],
            zipcode: result.payload.doc.data()["zipcode"]
          }

        });

      });
    }catch(error){
      this.toastservice.showToast(error.message, 2000);
      //console.log(error.message);
    }
  }
}
