import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { DbService } from 'src/app/services/db.service';
import { ServerService } from 'src/app/services/server.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-new-reminder',
  templateUrl: './new-reminder.component.html',
  styleUrls: ['./new-reminder.component.scss'],
})
export class NewReminderComponent implements OnInit {

  @Input() thisUser: string;
  
  public requestlist = [];
  public appointmentlist = [];
  public isLoading: any = true;
  

  
  modifyform: FormGroup;
  public docid: string;

  patient;

  constructor(
    public modifyformbuilder: FormBuilder,
    private serverservice: ServerService,
    private toastservice: ToastService,
    private fbstore: AngularFirestore,
    public alertcontrol: AlertController,
    //private loaderservice : LoaderService,
    private navCtrl: NavController,
    //private popoverController:PopoverController
    private modalCtrl :ModalController,
    private Dbservice: DbService
  ) { 

    
    this.modifyform = this.modifyformbuilder.group({
      name: ['', [Validators.required]],
      date: ['', [Validators.required ] ],
      reason: ['', [Validators.required, Validators.minLength(6)] ],
      

    })

  }
  

  ngOnInit() {}

  closeModal(){
    this.modalCtrl.dismiss({
      'dismissed': true,
      'reload' : false
    });
  }

  async doModify() {

    if (!this.patient) {
      this.patient = "null"
    }
    
    let reminder = {
      title: this.modifyform.get('name').value,
      date: this.modifyform.get('date').value,
      reason: this.modifyform.get('reason').value,
    }

    //console.log("productobj: ", reminder)
    //console.log("productobj: ", this.thisUser)
    try{
      await this.fbstore.collection(`/users/${this.thisUser}/reminders`).add(reminder).then(async data => {
      
        console.log(data);
       // this.ngroute.navigate(['requests']);
      let reload = false;
       const today = new Date();

      if (new Date(reminder.date).toLocaleDateString() == today.toLocaleDateString()){
        reload = true;
      }
       this.modalCtrl.dismiss({

        'dismissed': true,
        'reload' : reload
      });
      })
    }catch(error){
      this.toastservice.showToast(error.message, 2000);
      console.log(error.message);
    }
  }


}
